var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex md12 xs12"},[_c('vuestic-widget',{attrs:{"headerText":_vm._f("translate")('call-campaign.edit-flow.title')}},[_c('div',{staticClass:"edit-flow"},[(_vm.step === 0)?_c('div',{staticClass:"details d-flex flex-column align-items-center"},[_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-12 mb-4"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('call-campaign.edit-flow.form.flow-name.label')))]),_c('b-form-input',{staticClass:"border",class:{ 'is-invalid': _vm.errors.name },attrs:{"placeholder":_vm.$t('call-campaign.edit-flow.form.flow-name.placeholder')},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),(_vm.errors.name)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errors.name))]):_vm._e()],1),_c('div',{staticClass:"col-12 mb-4"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('call-campaign.edit-flow.form.flow-description.label')))]),_c('b-form-textarea',{staticClass:"border",class:{ 'is-invalid': _vm.errors.description },attrs:{"placeholder":_vm.$t(
                    'call-campaign.edit-flow.form.flow-description.placeholder'
                  ),"rows":"3","max-rows":"6"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}}),(_vm.errors.description)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errors.description))]):_vm._e()],1)]),_c('div',{staticClass:"row w-100 mb-4"},[_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('call-campaign.edit-flow.form.trials-count.label')))]),_c('b-form-input',{staticClass:"border",class:{ 'is-invalid': _vm.errors.trialsCount },attrs:{"type":"number","min":"0","placeholder":_vm.$t('call-campaign.edit-flow.form.trials-count.placeholder')},model:{value:(_vm.model.trialsCount),callback:function ($$v) {_vm.$set(_vm.model, "trialsCount", $$v)},expression:"model.trialsCount"}}),(_vm.errors.trialsCount)?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors.trialsCount)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t( 'call-campaign.edit-flow.form.minutes-to-wait-before-first-trial.label' )))]),_c('b-form-input',{staticClass:"border",class:{
                  'is-invalid': _vm.errors.minutesToWaitBeforeFirstTrial
                },attrs:{"type":"number","min":"0","placeholder":_vm.$t(
                    'call-campaign.edit-flow.form.minutes-to-wait-before-first-trial.placeholder'
                  )},model:{value:(_vm.model.minutesToWaitBeforeFirstTrial),callback:function ($$v) {_vm.$set(_vm.model, "minutesToWaitBeforeFirstTrial", $$v)},expression:"model.minutesToWaitBeforeFirstTrial"}}),(_vm.errors.minutesToWaitBeforeFirstTrial)?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors.minutesToWaitBeforeFirstTrial)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t( 'call-campaign.edit-flow.form.delay-minutes-between-trials.label' )))]),_c('b-form-input',{staticClass:"border",class:{
                  'is-invalid': _vm.errors.delayMinutesBetweenTrials
                },attrs:{"type":"number","min":"0","placeholder":_vm.$t(
                    'call-campaign.edit-flow.form.delay-minutes-between-trials.placeholder'
                  )},model:{value:(_vm.model.delayMinutesBetweenTrials),callback:function ($$v) {_vm.$set(_vm.model, "delayMinutesBetweenTrials", $$v)},expression:"model.delayMinutesBetweenTrials"}}),(_vm.errors.delayMinutesBetweenTrials)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errors.delayMinutesBetweenTrials))]):_vm._e()],1)]),_c('div',{staticClass:"row w-100 mb-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row mr-5"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('call-campaign.edit-flow.form.countries.label')))])]),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('call-campaign.edit-flow.form.caller-ids.label')))])])]),_vm._l((_vm.model.callerIds),function(caller,idx){return _c('div',{key:caller.id},[_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('div',{staticClass:"flex-fill"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-select',{staticClass:"border",class:{
                            'is-invalid':
                              caller.errors && caller.errors.length > 0
                          },attrs:{"value":_vm.model.callerIds[idx].destination},on:{"change":function($event){return _vm.onCountryChange($event, idx, caller.id)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.form.countries.placeholder' ))+" ")])]},proxy:true}],null,true)},_vm._l((_vm.countries),function(country){return _c('b-form-select-option',{key:country.code,attrs:{"value":country.code,"disabled":_vm.model.callerIds[idx].destination !==
                                country.code &&
                                _vm.selectedCountries.includes(country.code)}},[_vm._v(" "+_vm._s(country.emoji)+" "+_vm._s(country.name)+" ")])}),1),_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors[`callerIds[${idx}].destination`])+" ")])],1),_c('div',{staticClass:"col-6"},[_c('b-form-select',{staticClass:"border",class:{
                            'is-invalid':
                              caller.errors && caller.errors.length > 0
                          },attrs:{"options":_vm.callerIds},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.form.caller-ids.placeholder' ))+" ")])]},proxy:true}],null,true),model:{value:(_vm.model.callerIds[idx].callerId),callback:function ($$v) {_vm.$set(_vm.model.callerIds[idx], "callerId", $$v)},expression:"model.callerIds[idx].callerId"}}),_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors[`callerIds[${idx}].callerId`])+" ")])],1)])]),(_vm.model.callerIds.length > 1)?_c('button',{staticClass:"btn btn-danger mx-2 px-3 py-2",on:{"click":function($event){return _vm.removeCaller(idx)}}},[_vm._v(" - ")]):_vm._e()])])}),_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors[`callerIds`])+" ")]),_c('button',{staticClass:"btn btn-outline-primary btn-block py-3 mb-4",on:{"click":function($event){return _vm.addNewCaller()}}},[_vm._v(" + ")])],2)]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.onNext}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.edit-flow.edit'))+" ")])]):_vm._e(),(_vm.step === 1)?_c('div',{staticClass:"sound-editor d-flex flex-column"},[_c('div',{staticClass:"body py-4 mb-4"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileUploading),expression:"fileUploading"}],staticClass:"pre-loader--container"},[_c('radar-spinner',{staticClass:"m-auto",attrs:{"animation-duration":2000,"size":150,"color":"#008ecf"}})],1),(_vm.selectedCallFlow && !_vm.fileUploading)?_c('div',{staticClass:"node-editor"},[_c('input',{ref:"fileUpload",attrs:{"type":"file","accept":".mp3,.wav","hidden":""},on:{"input":_vm.onFileChoose}}),_c('node-editor',{attrs:{"nodes":_vm.callFlow.nodes,"disabled":""},scopedSlots:_vm._u([{key:"node",fn:function({data, idx}){return [_c('div',{staticClass:"node d-flex flex-column align-items-center justify-content-center",class:{
                        active: _vm.activeNode.value === data.value,
                        file: data.file
                      },attrs:{"background-color":"green"},on:{"click":function($event){return _vm.onNodeClick(_vm.callFlow.nodes[idx])}}},[_c('h5',{staticClass:"m-0 capitalize mb-2"},[_vm._v(_vm._s(data.name))]),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.onSoundUpload(data)}}},[_vm._v(" "+_vm._s(data.file ? _vm.$t( 'call-campaign.edit-flow.node-editor.uploaded' ) : _vm.$t( 'call-campaign.edit-flow.node-editor.upload-base-sound' ))+" ")])])]}}],null,false,2834246746)})],1):_vm._e(),_c('div',{staticClass:"player"},[_c('sound-player',{attrs:{"soundFile":_vm.selectedFileUrl,"initialMarkers":_vm.activeNode.params,"markers":_vm.markers},on:{"ready":_vm.onSoundPlayerReady,"statusChange":function($event){_vm.status = $event},"progressChange":function($event){_vm.progress = $event},"regionUpdated":_vm.onRegionUpdate,"regionRemoved":_vm.onRegionRemove}},[_c('template',{slot:"actions"},[_c('b-button',{staticClass:"mx-2",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.openPopup}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.sound-player.add-custom-variable' ))+" ")])],1)],2)],1)])]),_c('div',{staticClass:"foot d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary mx-3",on:{"click":function($event){return _vm.onBack()}}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.edit-flow.back'))+" ")]),_c('button',{ref:"scrollTarget",staticClass:"btn btn-primary",on:{"click":_vm.onEdit}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.edit-flow.edit'))+" ")])])]):_vm._e()])])],1),_c('modal',{attrs:{"isOpen":_vm.addMarkerModalOpen},on:{"dismiss":_vm.dismissPopup}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"head mb-4"},[_c('h1',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.edit-flow.custom-variable-modal.title'))+" ")])]),_c('div',{staticClass:"body d-flex flex-column align-items-center"},[_c('b-form-input',{staticClass:"border mb-2",attrs:{"placeholder":_vm.$t(
              'call-campaign.edit-flow.custom-variable-modal.form.variable-name.placeholder'
            )},model:{value:(_vm.markerModel.variable_name),callback:function ($$v) {_vm.$set(_vm.markerModel, "variable_name", $$v)},expression:"markerModel.variable_name"}}),_c('b-form-select',{staticClass:"border mb-2",attrs:{"value":_vm.markerModel.type,"options":_vm.markerTypeOptions},on:{"change":function($event){return _vm.onMarkerTypeChange({ type: $event })}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.custom-variable-modal.form.variable-type.placeholder' ))+" ")])]},proxy:true}])}),_c('b-form-select',{staticClass:"border mb-2",attrs:{"options":_vm.tongueOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":null}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.custom-variable-modal.form.tongue.placeholder' ))+" ")])]},proxy:true}]),model:{value:(_vm.markerModel.tongue),callback:function ($$v) {_vm.$set(_vm.markerModel, "tongue", $$v)},expression:"markerModel.tongue"}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onAddMarker}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.edit-flow.custom-variable-modal.form.add-custom-variable' ))+" ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }